const initialState = {
	success: false,
	print: true,
	toggleForm: false,
	reset: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "success":
			return {
				...state,
				success: action.payload,
			};

		case "PRINT":
			return {
				...state,
				print: action.payload,
			};
		case "TF":
			return {
				...state,
				toggleForm: action.payload,
			};
		case "RESET":
			return {
				...state,
				reset: action.payload,
			};

		default:
			return state;
	}
}
